<template>
  <div>
    <NavBar :navSelIndex="3"></NavBar>
    <b-container class="container-t-mode" fluid>
      <b-row class="header-search-box">
        <b-col class="header-search-title bv-d-lg-down-none bv-d-lx-down-none" sm="1" md="8" lg="4" xl="4">
          <img class="header-search-img" src="../../../public/icon/planeScheme/epeida-logo@2x.png" />
        </b-col>
        <!-- <b-col class="search-box" sm="1" md="12" lg="4" xl="4">
          <div class="search-input-box">
            <input v-model="keyWords" class="search-input-info" :placeholder="searchPlaceholder" @keyup.enter="search" />

            <div class="search-split-box margin-left-auto">
              <img src="../../../public/icon/product-distingush@2x.png" class="search-icon" />
            </div>
            <div class="search-split-box">
              <img src="../../../public/icon/search-split@2x.png" class="search-split" />
            </div>
            <div class="search-split-box margin-right-22" @click="search">
              <img src="../../../public/icon/search-icon@2x.png" class="search-icon" />
            </div>
          </div>
          <div class="key-words-box">
            <span class="key-words-title">{{$t("homeSearchPlaceholder")}}:</span>
            <span class="key-words" v-for="(item,index) in keyWordsList" :key="index"
              @click="keyWords=item">{{item}}</span>
          </div>
        </b-col> -->
        <!-- <b-col class="start-design" sm="1" md="12" lg="4" xl="4">
          <el-button type="warning" @click="toEpeida">开始设计</el-button>
        </b-col> -->

      </b-row>
    </b-container>
    <b-row class="row-line"></b-row>
    <b-container class="container-t-mode container-t-mode-wrap" fluid>

      <b-row class="category-box">
        <b-col lg="1" lx="1" md="2" sm="4" class="category-title">
          {{$t("planeSchemeTypeTitle")}}
        </b-col>
        <b-col lg="11" lx="11" md="10" class="category-item-box">
          <b-row class="category-item-row">
            <el-checkbox-group v-model="achieveList" class="category-item-group" @change="achieveChange">
              <el-checkbox class="category-item-check-item" v-for="achieve in screenParams.achieve" :label="achieve.id"
                :key="achieve.id">{{achieve.achieveName}}
              </el-checkbox>
            </el-checkbox-group>
          </b-row>
        </b-col>
      </b-row>
      <!-- <b-row class="category-box">
        <b-col lg="1" lx="1" md="2" sm="4" class="category-title">
          {{$t("planeSchemeSpaceTitle")}}
        </b-col>
        <b-col lg="11" lx="11" md="10" class="category-item-box">
          <b-row class="category-item-row"> -->
            <!-- <div class="category-item-col" :class="[styleId == item.categoryId?'category-item-col-sel':'']"
							v-for="(item,index) in styleCategory" :key="index" @click="styleId=item.categoryId">
							{{item.name}}
						</div> -->
            <!-- <el-checkbox-group v-model="spaceIdList" class="category-item-group" @change="spaceChange">
              <el-checkbox class="category-item-check-item" v-for="space in screenParams.space" :label="space"
                :key="space">{{space}}
              </el-checkbox>
            </el-checkbox-group>
          </b-row>
        </b-col>
      </b-row> -->
      <b-row class="category-box">
        <b-col lg="1" lx="1" md="2" sm="4" class="category-title">
          {{$t("styleCategory")}}
        </b-col>
        <b-col lg="11" lx="11" md="10" class="category-item-box">
          <b-row class="category-item-row">
            <!-- <div class="category-item-col" @click="styleId=''"
							:class="[styleId == ''?'category-item-col-sel':'']">
							{{$t('allCategoryTitle')}}
						</div>
						<div class="category-item-col" :class="[styleId == item.categoryId?'category-item-col-sel':'']"
							v-for="(item,index) in styleCategory" :key="index" @click="styleId=item.categoryId">
							{{item.name}}
						</div> -->
            <el-checkbox-group v-model="styleIdList" class="category-item-group" @change="styleChange">
              <el-checkbox class="category-item-check-item" v-for="style in screenParams.style" :label="style.id"
                :key="style.id">{{style.styleName}}
              </el-checkbox>
            </el-checkbox-group>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <b-row class="row-line margin-20-0"></b-row>

    <b-container class="container-t-mode container-t-mode-wrap" fluid>
      <b-row class="sort-screen-box">
        <b-col class="sort-items-inner">
          <b-row>
            <b-col lg="5" xl="5" md="6" sm="12" class="sort-box">
              <div class="sort-box-row">
                <div class="sort-title"><i class="el-icon-sort"></i>{{$t("tModeSortTitle")}}</div>
                <div class="sort-item" @click="allSort" :class="[sort == 0?'sort-item-sel':'']">
                  <div class="text">{{$t("tModeSortAll")}}</div>
                </div>
                <div class="sort-item" @click="comprehensive" :class="[sort == 1?'sort-item-sel':'']">
                  <div class="text">{{$t("tModeSortCompreheive")}}</div>
                </div>
                <div class="sort-item" @click="hot" :class="[sort == 2?'sort-item-sel':'']">
                  <div class="text">{{$t("tModeSortHot")}}</div>
                </div>
                <div class="sort-item" @click="newest" :class="[sort == 3?'sort-item-sel':'']">
                  <div class="text">{{$t("tModeSortNew")}}</div>
                </div>
                <!-- <div class="sort-item" @click="sort = 4" :class="[sort == 4?'sort-item-sel':'']">
                  <div class="text">{{$t("tModeSortCollection")}}</div>
                </div> -->
              </div>

            </b-col>
            <b-col lg="4" xl="4" md="5" sm="12" class="screen-box">
              <div class="screen-box-row">
                <div class="screen-title"><i class="el-icon-s-open"></i>{{$t("tModeScreenTitle")}}</div>
                <div class="screen-item" @click="screenAll" :class="[screenType == ''?'screen-item-sel':'']">
                  <div class="text">{{$t("allCategoryTitle")}}</div>
                </div>
                <div class="screen-item" @click="screenFree" :class="[screenType == 1?'screen-item-sel':'']">
                  <div class="text">{{$t("tModeScreenFree")}}</div>
                </div>
                <div class="screen-item" @click="screenVip" :class="[screenType == 2?'screen-item-sel':'']">
                  <div class="text">{{$t("tModeScreenVip")}}</div>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>

      </b-row>
      <div class="solution-box video-box" ref="solution">
        <waterfall :col="colCount" :data="masterpiecesList" :lazyDistance="200" @finish="finish" class="solution">
          <template>
            <div class="solution-item" v-for="(item,index) in masterpiecesList" :key="index" @click="to2DDetail(item)">
              <div class="solution-item-ehome">
                <img class="solution-img" :lazy-src="item.coverUri" />
                <div class="collection-button" @click.stop="followSolution(item)">
                  <i class="el-icon-star-off" v-if="item.collectStatus==0"></i>
                  <i class="el-icon-star-on" v-else></i>
                  <div class="collection-msg" v-if="item.collectStatus==0">{{$t('collectionButtonTitle')}}</div>
                  <div class="collection-msg" v-else>{{$t('cancleButtonTItle')}}</div>
                </div>
                <div class="solution-name ehome-solution-title-name">{{item.name}}</div>
                <!-- <div class="solution-user-box">
                  <img class="solution-user-avatar" :src="item.headImgUri"
                    v-if="item.headImgUri !='' && item.headImgUri != null" />
                  <img class="solution-user-avatar" src="img/person.431c96c3.png" v-else />
                  <div class="solution-designer-name ehome-solution-name"
                    v-if="null == item.nickName || ''==item.nickName">{{$t('portalTitle')}}</div>
                  <div class="solution-designer-name ehome-solution-name" v-else>{{item.nickName}}</div>
                  <i class="el-icon-view watch-icon"></i>
                  <div class="solution-watch-number ehome-solution-name">
                    {{item.likedNumber==null?0:item.likedNumber}}
                  </div>

                </div> -->
              </div>
            </div>
          </template>
        </waterfall>
      </div>
      <!-- <el-pagination v-if="totalPage >0" :page-count="totalPage" @current-change="changeCurrentPage"
        class="margin-top-80" background layout="prev, pager, next"></el-pagination> -->
      <div class="tip">{{!hasData ? '没有更多数据了' : ''}}</div>
    </b-container>

    <Right></Right>
    <Footer></Footer>
  </div>
</template>

<script>
  import NavBar from "@/components/Navbar.vue";
  import Footer from "@/components/Footer.vue";
  import Right from "@/components/Right.vue";
  import dump from "../../util/dump.js";
  export default {
    components: {
      NavBar,
      Footer,
      Right
    },
    data() {
      return {
        colCount: 5,
        keyWords: "",
        searchPlaceholder: this.$t('modePlaceholder'),
        keyWordsList: ["现代客厅", "极简", "美学"],
        categoryOneId: "",
        categoryTwoId: "",
        categoryThreeId: "",
        sort: 0,
        sortId: "",
        screenId: "",
        screenType: '',
        styleId: '',
        brandId: "",
        totalPage: 0,
        currentPage: 1,
        screenParams: [],
        categoryOne: [{
          "name": "家具馆",
          "categoryId": 2
        }, {
          "name": "灯饰馆",
          "categoryId": 24
        }, {
          "name": "家纺馆",
          "categoryId": 4
        }, {
          "name": "家饰馆",
          "categoryId": 21
        }],
        categoryTwo: [],
        categoryThree: [],
        // styleCategory: [{
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 16,
        //   "SORT": 1,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/da8b21a3-916f-447a-8b60-2e95bb517619.png",
        //   "LABEL_NAME": "极简"
        // }, {
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 15,
        //   "SORT": 2,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/1bb4a33d-6773-4042-9ec9-bb8b3133cb7a.png",
        //   "LABEL_NAME": "轻奢"
        // }, {
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 53,
        //   "SORT": 3,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/233d7b53-5eb7-41a8-bee7-dc1a5706f4bb.png",
        //   "LABEL_NAME": "中式"
        // }, {
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 52,
        //   "SORT": 4,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/4eeb3a66-cb58-49c4-bd29-ac97cb0949b2.png",
        //   "LABEL_NAME": "美式"
        // }, {
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 54,
        //   "SORT": 5,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/e1a1f2f5-efff-495f-b960-9a687c32bc27.png",
        //   "LABEL_NAME": "北欧/现代"
        // }, {
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 55,
        //   "SORT": 6,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/5b3615ea-e23d-4ca9-9d15-9680ba2cd0fc.png",
        //   "LABEL_NAME": "英式"
        // }, {
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 56,
        //   "SORT": 7,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/74f11586-60b1-45ac-a65b-487d94dc953d.png",
        //   "LABEL_NAME": "欧式/新古典"
        // }, {
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 643,
        //   "SORT": 8,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/bcac77e0-c34a-4391-bb3b-56cd3a3423b4.png",
        //   "LABEL_NAME": "床垫"
        // }, {
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 644,
        //   "SORT": 9,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/2263dba8-4cf7-4655-aeb1-c807d0868bef.png",
        //   "LABEL_NAME": "户外"
        // }, {
        //   "PHONE_IMG": "",
        //   "LABEL_ID": 645,
        //   "SORT": 10,
        //   "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/beb15d34-b455-4d42-94c9-bb2f634822a3.png",
        //   "LABEL_NAME": "儿童"
        // }],
        brands: [],
        allBrands: [],
        showAllBrands: false,
        solutionList: [{
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/a2a251a4-0041-4e7f-997d-354882b92613.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
          {
            coverUri: "https://szepeida.oss-cn-hangzhou.aliyuncs.com/app-file/487ea75b-21d2-4fe8-a30e-a874072f0acf.png",
            name: "静享“家”的温暖",
            designerName: "许淇栋（形与品设计）",
            watchNumber: 124,
            designerHeagImgUrl: "http://beiru.oss-cn-hangzhou.aliyuncs.com/app-file/a54e14c8-10ef-478f-bdd8-5ea639091167.png"
          },
        ],
        labelList: [{
          "LABEL_ID": 2,
          "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/eb8a93c6-195f-461e-838c-283f9725e3fa.png",
          "LABEL_NAME": "家具馆",
          "label2": [{
            "PHONE_IMG": "",
            "LABEL_ID": 5,
            "SORT": 1,
            "IMG_PATH": "",
            "LABEL_NAME": "客厅",
            "label3": [{
              "PHONE_IMG": "\r\nhttps://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_09.png",
              "LABEL_ID": 623,
              "SORT": 10,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/5a3915cd-334e-4e80-ae49-77093ff0ad99.png",
              "LABEL_NAME": "沙发"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_11.png",
              "LABEL_ID": 624,
              "SORT": 20,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/8fb024d4-7595-4231-bf13-71ca819468ba.png",
              "LABEL_NAME": "茶几"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_55.png",
              "LABEL_ID": 696,
              "SORT": 25,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/9016fa0d-96c7-49ec-859d-e9caaf495724.png",
              "LABEL_NAME": "茶台"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_13.png",
              "LABEL_ID": 625,
              "SORT": 30,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/02d394e2-aab7-4404-8888-a333077afb21.png",
              "LABEL_NAME": "角几"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_23.png",
              "LABEL_ID": 626,
              "SORT": 40,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/72ef47ba-646b-456c-a09a-d4da3ca0c27c.png",
              "LABEL_NAME": "电视柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB%E6%95%88%E6%9E%9C1_25.png",
              "LABEL_ID": 627,
              "SORT": 50,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/d485841b-0fc2-4b33-af6e-39a0899c8e86.png",
              "LABEL_NAME": "休闲椅"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20200624104521.jpg",
              "LABEL_ID": 699,
              "SORT": 55,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/8f63f98e-f590-4e99-acda-b56794c22c40.png",
              "LABEL_NAME": "茶椅"
            }, {
              "PHONE_IMG": "\r\nhttps://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_27.png",
              "LABEL_ID": 628,
              "SORT": 60,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/385cc687-3c58-446c-bee6-94786c004c62.png",
              "LABEL_NAME": "酒柜/装饰柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_37.png",
              "LABEL_ID": 629,
              "SORT": 70,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/a8da87d0-19c4-40fb-82cc-e195123f3895.png",
              "LABEL_NAME": "玄关柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_39.png",
              "LABEL_ID": 630,
              "SORT": 80,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/fdca8d29-ca53-40d5-a26a-66d6a7e1502d.png",
              "LABEL_NAME": "斗柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_41.png",
              "LABEL_ID": 631,
              "SORT": 90,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/6e14ce28-7b22-4ea8-96c4-81738996c2fb.png",
              "LABEL_NAME": "脚踏/圆凳"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_51.png",
              "LABEL_ID": 632,
              "SORT": 100,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/72927026-ddb7-4b57-919e-34cbfa32caaa.png",
              "LABEL_NAME": "鞋柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB1_53.png",
              "LABEL_ID": 633,
              "SORT": 110,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0bcbc0a7-a06b-4330-8114-15d5f698a984.png",
              "LABEL_NAME": "吧凳"
            }, {
              "PHONE_IMG": "\r\nhttps://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20200624104526.jpg",
              "LABEL_ID": 700,
              "SORT": 115,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/ee53f315-a2c1-403d-8783-fb9f480a2118.png",
              "LABEL_NAME": "花架"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 107,
            "SORT": 2,
            "IMG_PATH": "",
            "LABEL_NAME": "餐厅",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB2_09.png",
              "LABEL_ID": 634,
              "SORT": 120,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/b94dc0fd-6bc8-4bed-976b-c8a1446f73e0.png",
              "LABEL_NAME": "餐桌"
            }, {
              "PHONE_IMG": "\r\nhttps://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB2_11.png",
              "LABEL_ID": 635,
              "SORT": 130,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/959bfb1f-0e05-4dd7-9fa8-4356ecd2624c.png",
              "LABEL_NAME": "餐椅"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB2_13.png",
              "LABEL_ID": 636,
              "SORT": 140,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/6df4dbf9-c3fa-489c-9e46-3d29e2f5aec0.png",
              "LABEL_NAME": "餐边柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB2_23.png",
              "LABEL_ID": 637,
              "SORT": 150,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/4a28cb79-72ec-4fa4-9ec2-bc6efcf9742c.png",
              "LABEL_NAME": "酒柜"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 106,
            "SORT": 3,
            "IMG_PATH": "",
            "LABEL_NAME": "卧室",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB3_09.png",
              "LABEL_ID": 614,
              "SORT": 160,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/5edc7de2-d588-4ca6-93db-5503c7269627.png",
              "LABEL_NAME": "床"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB3_11.png",
              "LABEL_ID": 615,
              "SORT": 170,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/3f2d187f-9fe7-46d8-8722-45acd1458c37.png",
              "LABEL_NAME": "床垫"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB3_13.png",
              "LABEL_ID": 616,
              "SORT": 180,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/4da3a646-6b79-417b-a56f-077bd6a9f8c7.png",
              "LABEL_NAME": "衣柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB3_23.png",
              "LABEL_ID": 617,
              "SORT": 190,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/68dbfa15-18ca-4b51-9d0a-8c374e131907.png",
              "LABEL_NAME": "床头柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB3_25.png",
              "LABEL_ID": 618,
              "SORT": 200,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/b79486ee-8421-4d74-8b38-bf321697c08e.png",
              "LABEL_NAME": "床尾凳"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB3_27.png",
              "LABEL_ID": 619,
              "SORT": 210,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/b849bdf4-06a4-4b81-97a6-308d145b0290.png",
              "LABEL_NAME": "梳妆台/妆镜/凳"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB3_37.png",
              "LABEL_ID": 620,
              "SORT": 220,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/dc424683-8b5b-4768-9315-c96b0230a724.png",
              "LABEL_NAME": "穿衣镜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB3_39.png",
              "LABEL_ID": 621,
              "SORT": 230,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/1868990e-c32a-452b-8b93-381e12aff435.png",
              "LABEL_NAME": "衣帽架"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 111,
            "SORT": 4,
            "IMG_PATH": "",
            "LABEL_NAME": "书房",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB4_09.png",
              "LABEL_ID": 602,
              "SORT": 250,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/737bc2b1-50b8-42e5-a107-80cad235ace1.png",
              "LABEL_NAME": "书桌"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB4_11.png",
              "LABEL_ID": 603,
              "SORT": 260,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/de4d6a8a-fdb5-48c6-901e-f90c312b8b37.png",
              "LABEL_NAME": "书椅"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB4_13.png",
              "LABEL_ID": 604,
              "SORT": 270,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/0a4c4b0c-4124-4b26-865f-ce4f8964f786.png",
              "LABEL_NAME": "书柜"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 112,
            "SORT": 5,
            "IMG_PATH": "",
            "LABEL_NAME": "儿童房",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB5_09.png",
              "LABEL_ID": 605,
              "SORT": 280,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/ebd0c54c-6423-44ca-8973-ffca982fbc0a.png",
              "LABEL_NAME": "儿童床"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB5_11.png",
              "LABEL_ID": 606,
              "SORT": 290,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/bc6f1c56-bb0f-45a4-be9e-9215054d2b37.png",
              "LABEL_NAME": "儿童书桌"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB5_13.png",
              "LABEL_ID": 607,
              "SORT": 300,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/b833b39f-56f5-41cc-9076-500e852938ec.png",
              "LABEL_NAME": "儿童衣柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB5_25.png",
              "LABEL_ID": 609,
              "SORT": 310,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/4580d105-f848-474e-bbb5-a4fae09c743d.png",
              "LABEL_NAME": "儿童书椅"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB5_23.png",
              "LABEL_ID": 608,
              "SORT": 320,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/1ddc9070-6c7c-43bb-9733-9ab433025e36.png",
              "LABEL_NAME": "儿童床头柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB5_27.png",
              "LABEL_ID": 610,
              "SORT": 330,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/7ea6cc8b-2824-4642-8e42-6c24761af66d.png",
              "LABEL_NAME": "儿童穿衣镜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB5_37.png",
              "LABEL_ID": 611,
              "SORT": 340,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/03989003-5619-44ad-84e5-1ca9e7251270.png",
              "LABEL_NAME": "儿童衣帽架"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB5_39.png",
              "LABEL_ID": 612,
              "SORT": 350,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/5e095513-2f02-4e14-b995-07f2d5f565b0.png",
              "LABEL_NAME": "儿童斗柜"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20200624104511.jpg",
              "LABEL_ID": 701,
              "SORT": 355,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/69179a0f-7091-4a55-8428-4397f2cd14b9.png",
              "LABEL_NAME": "儿童书柜"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 113,
            "SORT": 6,
            "IMG_PATH": "",
            "LABEL_NAME": "户外",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB6_25.png",
              "LABEL_ID": 642,
              "SORT": 400,
              "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/e0955d20-e81f-46a9-98c6-6243d19dac34.png",
              "LABEL_NAME": "户外"
            }]
          }]
        }, {
          "LABEL_ID": 24,
          "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/66d0a245-c83e-4209-bd4f-1f16dadf5b7a.png",
          "LABEL_NAME": "灯饰馆",
          "label2": [{
            "PHONE_IMG": "",
            "LABEL_ID": 119,
            "SORT": 1,
            "IMG_PATH": "",
            "LABEL_NAME": "吸顶灯",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB7_09.png",
              "LABEL_ID": 181,
              "SORT": 421,
              "IMG_PATH": "",
              "LABEL_NAME": "水晶吸顶灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB7_11.png",
              "LABEL_ID": 182,
              "SORT": 422,
              "IMG_PATH": "",
              "LABEL_NAME": "铜艺吸顶灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB7_13.png",
              "LABEL_ID": 183,
              "SORT": 423,
              "IMG_PATH": "",
              "LABEL_NAME": "铁艺吸顶灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB7_23.png",
              "LABEL_ID": 184,
              "SORT": 424,
              "IMG_PATH": "",
              "LABEL_NAME": "玻璃吸顶灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB7_25.png",
              "LABEL_ID": 313,
              "SORT": 425,
              "IMG_PATH": "",
              "LABEL_NAME": "木质吸顶灯"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 118,
            "SORT": 2,
            "IMG_PATH": "",
            "LABEL_NAME": "吊灯",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB8_09.png",
              "LABEL_ID": 185,
              "SORT": 431,
              "IMG_PATH": "",
              "LABEL_NAME": "水晶吊灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB8_11.png",
              "LABEL_ID": 186,
              "SORT": 432,
              "IMG_PATH": "",
              "LABEL_NAME": "铜艺吊灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB8_13.png",
              "LABEL_ID": 187,
              "SORT": 433,
              "IMG_PATH": "",
              "LABEL_NAME": "铁艺吊灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB8_23.png",
              "LABEL_ID": 188,
              "SORT": 434,
              "IMG_PATH": "",
              "LABEL_NAME": "玻璃吊灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB8_25.png",
              "LABEL_ID": 189,
              "SORT": 435,
              "IMG_PATH": "",
              "LABEL_NAME": "不锈钢吊灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB8_27.png",
              "LABEL_ID": 190,
              "SORT": 436,
              "IMG_PATH": "",
              "LABEL_NAME": "复合材料吊灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB8_37.png",
              "LABEL_ID": 312,
              "SORT": 437,
              "IMG_PATH": "",
              "LABEL_NAME": "木质吊灯"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 117,
            "SORT": 3,
            "IMG_PATH": "",
            "LABEL_NAME": "台灯",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB9_09.png",
              "LABEL_ID": 191,
              "SORT": 441,
              "IMG_PATH": "",
              "LABEL_NAME": "水晶台灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB9_11.png",
              "LABEL_ID": 192,
              "SORT": 442,
              "IMG_PATH": "",
              "LABEL_NAME": "铜艺台灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB9_13.png",
              "LABEL_ID": 193,
              "SORT": 443,
              "IMG_PATH": "",
              "LABEL_NAME": "铁艺台灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB9_23.png",
              "LABEL_ID": 195,
              "SORT": 444,
              "IMG_PATH": "",
              "LABEL_NAME": "陶瓷台灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB9_25.png",
              "LABEL_ID": 311,
              "SORT": 445,
              "IMG_PATH": "",
              "LABEL_NAME": "玻璃台灯 "
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB9_27.png",
              "LABEL_ID": 373,
              "SORT": 446,
              "IMG_PATH": "",
              "LABEL_NAME": "不锈钢台灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB9_37.png",
              "LABEL_ID": 374,
              "SORT": 447,
              "IMG_PATH": "",
              "LABEL_NAME": "复合材料台灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB9_39.png",
              "LABEL_ID": 375,
              "SORT": 448,
              "IMG_PATH": "",
              "LABEL_NAME": "木质台灯"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 51,
            "SORT": 4,
            "IMG_PATH": "",
            "LABEL_NAME": "落地灯",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB10_09.png",
              "LABEL_ID": 198,
              "SORT": 451,
              "IMG_PATH": "",
              "LABEL_NAME": "水晶落地灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB10_11.png",
              "LABEL_ID": 199,
              "SORT": 452,
              "IMG_PATH": "",
              "LABEL_NAME": "铜艺落地灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB10_13.png",
              "LABEL_ID": 200,
              "SORT": 453,
              "IMG_PATH": "",
              "LABEL_NAME": "铁艺落地灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB10_23.png",
              "LABEL_ID": 201,
              "SORT": 454,
              "IMG_PATH": "",
              "LABEL_NAME": "玻璃落地灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB10_25.png",
              "LABEL_ID": 202,
              "SORT": 455,
              "IMG_PATH": "",
              "LABEL_NAME": "不锈钢落地灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB10_27.png",
              "LABEL_ID": 203,
              "SORT": 456,
              "IMG_PATH": "",
              "LABEL_NAME": "复合材料落地灯"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 50,
            "SORT": 5,
            "IMG_PATH": "",
            "LABEL_NAME": "壁灯",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB11_09.png",
              "LABEL_ID": 379,
              "SORT": 461,
              "IMG_PATH": "",
              "LABEL_NAME": "水晶壁灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB11_11.png",
              "LABEL_ID": 380,
              "SORT": 462,
              "IMG_PATH": "",
              "LABEL_NAME": "铜艺壁灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB11_13.png",
              "LABEL_ID": 381,
              "SORT": 463,
              "IMG_PATH": "",
              "LABEL_NAME": "铁艺壁灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB11_23.png",
              "LABEL_ID": 382,
              "SORT": 464,
              "IMG_PATH": "",
              "LABEL_NAME": "玻璃壁灯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB11_25.png",
              "LABEL_ID": 383,
              "SORT": 465,
              "IMG_PATH": "",
              "LABEL_NAME": "木质壁灯"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 704,
            "SORT": 6,
            "IMG_PATH": "",
            "LABEL_NAME": "其他",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210128145147.jpg",
              "LABEL_ID": 705,
              "SORT": 1,
              "IMG_PATH": "",
              "LABEL_NAME": "其他"
            }]
          }]
        }, {
          "LABEL_ID": 4,
          "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/f8e29ed5-f3a0-47c1-a375-5be5d73b3b10.png",
          "LABEL_NAME": "家纺馆",
          "label2": [{
            "PHONE_IMG": "",
            "LABEL_ID": 34,
            "SORT": 3,
            "IMG_PATH": "",
            "LABEL_NAME": "家纺",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB13_09.png",
              "LABEL_ID": 516,
              "SORT": 580,
              "IMG_PATH": "",
              "LABEL_NAME": "床品套件"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB13_11.png",
              "LABEL_ID": 517,
              "SORT": 590,
              "IMG_PATH": "",
              "LABEL_NAME": "枕芯/被芯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB13_13.png",
              "LABEL_ID": 518,
              "SORT": 595,
              "IMG_PATH": "",
              "LABEL_NAME": "抱枕"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB13_16.png",
              "LABEL_ID": 519,
              "SORT": 600,
              "IMG_PATH": "",
              "LABEL_NAME": "床幔/蚊帐"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB13_18.png",
              "LABEL_ID": 520,
              "SORT": 610,
              "IMG_PATH": "",
              "LABEL_NAME": "凉席"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB13_20.png",
              "LABEL_ID": 521,
              "SORT": 620,
              "IMG_PATH": "",
              "LABEL_NAME": "毛毯"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB13_30.png",
              "LABEL_ID": 522,
              "SORT": 630,
              "IMG_PATH": "",
              "LABEL_NAME": "面巾/浴巾/浴袍/手巾"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB13_32.png",
              "LABEL_ID": 523,
              "SORT": 640,
              "IMG_PATH": "",
              "LABEL_NAME": "拖鞋"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 26,
            "SORT": 4,
            "IMG_PATH": "",
            "LABEL_NAME": "窗帘布艺",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB12_09.png",
              "LABEL_ID": 524,
              "SORT": 470,
              "IMG_PATH": "",
              "LABEL_NAME": "窗帘"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB12_11.png",
              "LABEL_ID": 525,
              "SORT": 480,
              "IMG_PATH": "",
              "LABEL_NAME": "窗轨"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB12_13.png",
              "LABEL_ID": 526,
              "SORT": 490,
              "IMG_PATH": "",
              "LABEL_NAME": "辅料"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210128145153.png",
              "LABEL_ID": 527,
              "SORT": 500,
              "IMG_PATH": "",
              "LABEL_NAME": "面料"
            }]
          }]
        }, {
          "LABEL_ID": 21,
          "IMG_PATH": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/be44e86d-0af7-46f2-a07e-2c0cc39dff2b.png",
          "LABEL_NAME": "家饰馆",
          "label2": [{
            "PHONE_IMG": "",
            "LABEL_ID": 419,
            "SORT": 1,
            "IMG_PATH": "",
            "LABEL_NAME": "饰品摆件",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB18_09.png",
              "LABEL_ID": 468,
              "SORT": 650,
              "IMG_PATH": "",
              "LABEL_NAME": "摆件"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB18_11.png",
              "LABEL_ID": 469,
              "SORT": 660,
              "IMG_PATH": "",
              "LABEL_NAME": "花艺"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB18_23.png",
              "LABEL_ID": 471,
              "SORT": 680,
              "IMG_PATH": "",
              "LABEL_NAME": "托盘/果盘"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB18_25.png",
              "LABEL_ID": 472,
              "SORT": 690,
              "IMG_PATH": "",
              "LABEL_NAME": "烛台"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB18_27.png",
              "LABEL_ID": 473,
              "SORT": 700,
              "IMG_PATH": "",
              "LABEL_NAME": "相框"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB18_37.png",
              "LABEL_ID": 474,
              "SORT": 710,
              "IMG_PATH": "",
              "LABEL_NAME": "雕塑"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20210128145943.jpg",
              "LABEL_ID": 703,
              "SORT": 720,
              "IMG_PATH": "",
              "LABEL_NAME": "留声机"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 37,
            "SORT": 2,
            "IMG_PATH": "",
            "LABEL_NAME": "生活日用",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB17_09.png",
              "LABEL_ID": 444,
              "SORT": 550,
              "IMG_PATH": "",
              "LABEL_NAME": "餐饮用品"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB17_11.png",
              "LABEL_ID": 445,
              "SORT": 560,
              "IMG_PATH": "",
              "LABEL_NAME": "厨房用品"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB17_13.png",
              "LABEL_ID": 446,
              "SORT": 570,
              "IMG_PATH": "",
              "LABEL_NAME": "家居用品"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 36,
            "SORT": 3,
            "IMG_PATH": "",
            "LABEL_NAME": "墙饰画艺",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB16_07.png",
              "LABEL_ID": 433,
              "SORT": 510,
              "IMG_PATH": "",
              "LABEL_NAME": "画艺"
            }, {
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB16_09.png",
              "LABEL_ID": 434,
              "SORT": 520,
              "IMG_PATH": "",
              "LABEL_NAME": "壁饰"
            }]
          }, {
            "PHONE_IMG": "",
            "LABEL_ID": 35,
            "SORT": 4,
            "IMG_PATH": "",
            "LABEL_NAME": "地毯挂毯",
            "label3": [{
              "PHONE_IMG": "https://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB%E6%95%88%E6%9E%9C4_07.png",
              "LABEL_ID": 420,
              "SORT": 530,
              "IMG_PATH": "",
              "LABEL_NAME": "地毯"
            }, {
              "PHONE_IMG": "\r\nhttps://e-home.oss-cn-shenzhen.aliyuncs.com/file/%E6%89%8B%E6%9C%BA%E7%89%88%E8%AE%BE%E8%AE%A1-%E5%88%86%E7%B1%BB%E6%95%88%E6%9E%9C4_09.png",
              "LABEL_ID": 421,
              "SORT": 540,
              "IMG_PATH": "",
              "LABEL_NAME": "挂毯"
            }]
          }]
        }],
        // spaceList: [{
        //   name: "客厅",
        //   id: 1
        // }, {
        //   name: "餐厅",
        //   id: 2
        // }, {
        //   name: "卧室",
        //   id: 3
        // }],
        achieveList: [],
        spaceIdList: [],
        styleIdList: [],
        masterpiecesList: [],
        isRefreshBool: false,
        hasData: true
      }
    },
    created() {
      this.keyWords = this.$route.query.keyWords;
      let width = document.body.clientWidth;
      if (width <= 800 && width > 400) {
        this.colCount = 2;
      } else if (width <= 400) {
        this.colCount = 1;
      }
      this.getStyleList();
      this.getMasterpieces(1);
      this.getDesign2DSelectParams();
      // 监听页面滚动
      window.addEventListener('scroll', this.loadMore);
    },
    //页面离开后销毁，防止切换路由后上一个页面监听scroll滚动事件会在新页面报错问题 
    destroyed() { 
      window.removeEventListener('scroll', this.loadMore)
    },
    methods: {
      to2DDetail(item) {
        item.type = 3;
        item.link = "/twodimensionalprogramme";
        item.params = {
          id: item.id
        };
        dump.link(item);
      },
      getMasterpieces(pageIndex) {
        const that = this;
        that.masterpiecesList = [];
        that.$api.designMasterpieces({
          pageIndex: pageIndex,
          pageSize: 20,
          keyword: this.keyWords
        }).then((res => {
          if (res.code == 200) {
            that.masterpiecesList = res.data.list;
            that.totalPage = res.data.pageTotals;
          }
        }))
      },
      changeCurrentPage(info) {
        this.currentPage = info;
        this.getMasterpieces(info);
      },
      clearCategoryOne() {
        this.categoryOneId = '';
        this.categoryTwoId = '';
        this.categoryThreeId = '';
      },
      clearCategoryTwo() {
        this.categoryTwoId = '';
        this.categoryThreeId = '';
      },
      setCategoryOne() {
        const that = this;
        that.categoryOne = [];
        that.labelList.forEach((item, index) => {
          let tmp = {
            name: item.LABEL_NAME,
            categoryId: item.LABEL_ID,
            index: index
          }
          that.categoryOne.push(tmp);
        })
        // console.log(JSON.stringify(that.categoryOne));
        //配置labelMap
      },
      setCategoryTwo(category) {
        // console.log(category);
        const that = this;
        that.categoryOneId = category.categoryId;
        if (that.categoryOneId == '') {
          return false;
        }
        that.categoryTwo = [];
        that.labelList[category.index].label2.forEach((item, index) => {
          let tmp = {
            name: item.LABEL_NAME,
            categoryId: item.LABEL_ID,
            index: category.index,
            index2: index
          }
          that.categoryTwo.push(tmp);
        });

      },
      setCategoryThree(category) {
        console.log(category);
        const that = this;
        that.categoryTwoId = category.categoryId;
        if (that.categoryTwoId == '') {
          return false;
        }
        that.categoryThree = [];
        that.labelList[category.index].label2[category.index2].label3.forEach((item, index) => {
          let tmp = {
            name: item.LABEL_NAME,
            categoryId: item.LABEL_ID,
            index: category.index,
            index2: category.index2,
            index3: index
          }
          that.categoryThree.push(tmp);
        });

      },
      getLabelList() {
        const that = this;
        that.$api.labelList({}).then(res => {
          that.labelList = res.data;
          that.setCategoryOne();
        }).catch(() => {
          that.setCategoryOne();
        });
      },
      getStyleList() {
        const that = this;
        that.$api.styleList({}).then(res => {
          that.styleCategory = [];
          res.data.forEach((item) => {
            let tmp = {
              name: item.LABEL_NAME,
              categoryId: item.LABEL_ID,
            }
            that.styleCategory.push(tmp);
          })
        })
      },
      showMoreBrands() {
        const that = this;
        const brandsLength = that.brands.length;
        that.allBrands.forEach((item, index) => {
          if (index >= brandsLength) {
            that.brands.push(item);
          }
        });
        that.showAllBrands = true;
      },
      hiddenMoreBrands() {
        const that = this;
        that.showAllBrands = true;
        that.brands = [];
        that.allBrands.forEach((item, index) => {
          if (index >= 13) {
            return false;
          }
          that.brands.push(item);
        })
        that.showAllBrands = false;
      },
      getBrands() {
        const that = this;
        this.$api.brandList({}).then(res => {
          console.log(res);
          that.allBrands = res.data;
          that.allBrands.forEach((item, index) => {
            if (index >= 13) {
              return false;
            }
            that.brands.push(item);
          })
        })
      },
      // 获取二维方案产品类型、空间、风格
      getDesign2DSelectParams() {
        this.$api.design2DSelectParams({}).then(res => {
          if (res.code == 200) {
            this.screenParams = res.data;
          }
        }).catch(err => console.error(err))
      },
      // 选择空间
      spaceChange() {
        this.$api.designMasterpieces({
          pageIndex: 1,
          pageSize: 20,
          space: this.spaceIdList.join(",")
        }).then(res => {
          if (res.code == 200) {
            this.masterpiecesList = [];
            this.masterpiecesList = res.data.list;
            this.totalPage = res.data.pageTotals;
          }

        }).catch(err => console.error(err))
      },
      // 选择风格
      styleChange() {
        this.$api.designMasterpieces({
          pageIndex: 1,
          pageSize: 20,
          style: this.styleIdList.join(",")
        }).then(res => {
          if (res.code == 200) {
            this.masterpiecesList = [];
            this.masterpiecesList = res.data.list;
            this.totalPage = res.data.pageTotals;
          }

        }).catch(err => console.error(err))
      },
      // 选择类型
      achieveChange() {
        this.$api.designMasterpieces({
          pageIndex: 1,
          pageSize: 20,
          achieve: this.achieveList.join(",")
        }).then(res => {
          if (res.code == 200) {
            this.masterpiecesList = [];
            this.masterpiecesList = res.data.list;
            this.totalPage = res.data.pageTotals;
          }

        }).catch(err => console.error(err))
      },
      sortFn() {
        this.$api.designMasterpieces({
          pageIndex: 1,
          pageSize: 20,
          space: this.spaceIdList.join(","),
          style: this.styleIdList.join(","),
          achieve: this.achieveList.join(","),
          sort: this.sortId
        }).then(res => {
          if (res.code == 200) {
            this.masterpiecesList = [];
            this.masterpiecesList = res.data.list;
            this.totalPage = res.data.pageTotals;
          }

        }).catch(err => console.error(err))
      },
      // 全部
      allSort(){
        this.sort = 0;
        this.sortId = '';
        this.sortFn()
      },
      // 综合
      comprehensive() {
        this.sort = 1;
        this.sortId = 2;
        this.sortFn()
      },
      // 热门
      hot() {
        this.sort = 2;
        this.sortId = 1;
        this.sortFn()

      },
      // 最新
      newest() {
        this.sort = 3;
        this.sortId = 0;
        this.sortFn()
      },
      screenFn() {
        this.$api.designMasterpieces({
          pageIndex: 1,
          pageSize: 20,
          space: this.spaceIdList.join(","),
          style: this.styleIdList.join(","),
          achieve: this.achieveList.join(","),
          sort: this.sortId,
          fee: this.screenId
        }).then(res => {
          if (res.code == 200) {
            this.masterpiecesList = [];
            this.masterpiecesList = res.data.list;
            this.totalPage = res.data.pageTotals;
          }
        }).catch(err => console.error(err))
      },
      // 全部
      screenAll() {
        this.screenId = 3,
          this.screenType = "",
          this.screenFn();
      },
      // 免费
      screenFree() {
        this.screenId = 0,
          this.screenType = 1,
          this.screenFn();
      },
      // vip 
      screenVip() {
        this.screenId = 1,
          this.screenType = 2,
          this.screenFn();
      },
      // 跳转到e配搭
      toEpeida() {
        if (!dump.checkLogin()) {
          return false;
        }
        this.$api.epeida({
          token: localStorage.getItem("token")
        }).then(res => {
          dump.toEPeida(res);
        })
      },
      // 监听瀑布流渲染是否完成
      finish(){
        this.isRefreshBool = true;
        if(this.currentPage >= this.totalPage && this.isRefreshBool){
          this.hasData = false;
        }
      },
      // 加载更多
      loadMore(){
        // 获取 滚动高度
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        // 获取 内容盒子高度
        let solutionHeight = this.$refs.solution.clientHeight;
        // 获取 内容盒子到定位父级的高度
        let solutionOffsetTop = this.$refs.solution.offsetTop;

        // this.isRefreshBool = false防止加载数据函数多次触发, this.hasData判断是否还有数据
        if(scrollTop + solutionOffsetTop >= solutionHeight && this.isRefreshBool && this.hasData){
          this.isRefreshBool = false;
          this.currentPage += 1;
          this.$api.designMasterpieces({
            pageIndex: this.currentPage,
            pageSize: 20,
            keyword: this.keyWords
          }).then(res => {
            if (res.code == 200) {
              this.masterpiecesList = this.masterpiecesList.concat(res.data.list);
              
              // 判断是否到达最后一页
              if(this.currentPage >= this.totalPage){
                this.$message({
                  message: '数据已经全部加载完毕',
                  type: 'success'
                });
                
              }
              
            }
          })
        }
      },
      // 搜索
      search(){
        this.getMasterpieces(1);
      },
      // 收藏
      followSolution(solution) {
        if (!dump.checkLogin()) {
          return false;
        }
        // let showMsg;
        let flag;
        if (solution.collectStatus == 1) {
          // showMsg = this.$t("designerFollowOut");
          flag = 2;
        } else {
          // showMsg = this.$t("designerFollowIn");
          flag = 1;
        }
        let data = {
          flag: flag,
          colId: solution.id,
          colType: solution.colType || 1
        };
        this.$api.followSolution(data).then(res => {
          if (res.code == 200) {
            // this.$alert(showMsg);
            if (flag == 1) {
              solution.collectStatus = 1;
            } else {
              solution.collectStatus = 0;
            }
      
          }
        })
      
      },

    }


  }
</script>

<style lang="scss" scoped="scoped">
  @import "../../assets/scss/style.scss";

  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: $bg-default-s-color;
  }

  /deep/.el-checkbox__input.is-focus .el-checkbox__inner {
    color: $bg-default-s-color;
  }

  /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $bg-default-s-color;
    border-color: $bg-default-s-color;
  }

  /deep/.el-checkbox__inner:hover {
    border-color: $bg-default-s-color;
  }

  input:focus {
    outline: none;
  }

  .row-line {
    height: 0.06rem;
    background: #DCDCDC;
  }

  .margin-20-0 {
    margin: 1.25rem 0;
  }

  .margin-top-80 {
    margin-top: 5rem;
    margin-bottom: 5rem;
  }

  .header-search-title {
    text-align: left;
  }

  .start-design {
    text-align: right;
  }

  .el-button--warning {
    background-color: #d19d7e;
    border-color: #d19d7e;
  }

  .el-button--warning:focus,
  .el-button--warning:hover {
    background: #ebb563;
    border-color: #ebb563;
  }


  .container-t-mode {
    .header-search-box {
      padding: 3.75rem 5.3125rem;

      .header-search-title {
        .header-search-img {
          // width: 8.125rem;
          // height: 2.25rem;
          width: 11rem;
        }
      }
    }

    .search-box {
      // width: 39.875rem;
      height: 3.125rem;
      border-radius: 0.625rem;

      .search-input-box {
        height: 3.125rem;
        margin: auto;
        background: #F4F4F4;
        border: unset;
        display: flex;
        border-radius: 0.625rem;

        .search-input-info {
          border-top-left-radius: 0.625rem;
          border-bottom-left-radius: 0.625rem;
          background: #F4F4F4;
          border: unset;
          display: flex;
          width: 25rem;
          padding-left: 1.5rem;
        }

        .margin-left-auto {
          margin-left: auto;
        }

        .margin-right-22 {
          margin-right: 1.375rem;
        }

        .search-split-box {
          width: 1.4375rem;
          height: 100%;
          display: flex;

          .search-icon {
            width: 1.4375rem;
            // height: 1.4375rem;
            margin: auto;
            cursor: pointer;
          }

          .search-split {
            width: 0.0625rem;
            height: 1.4375rem;
            margin: auto;
          }
        }
      }

      .key-words-box {
        text-align: left;
        font-size: 0.91rem;
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #727171;
        padding-top: 1.38rem;

        .key-words-title {}

        .key-words {
          padding: 0 0.9rem;
          cursor: pointer;
        }
      }
    }



  }

  .container-t-mode-wrap {
    .category-box {
      padding-top: 0.6rem;
      text-align: left;

      .category-title {
        padding: 0.375rem 0 0.375rem 0.9375rem;
        margin: 0.6rem 0;
        text-align: justify;
        text-align-last: justify;
        max-width: 4.5rem;
        color: $select-search-category-title;
      }

      .category-item-box {
        display: flex;

        .category-item-row {
          align-items: center;

          .category-item-group {
            margin: auto 0;

            .category-item-check-item {
              margin: auto 0.625rem;
            }

            /deep/ .el-checkbox__input {
              line-height: 2.5;
            }
          }

          .category-item-col {
            font-size: 0.9375rem;
            font-family: Source Han Sans SC;
            font-weight: 400;
            color: $select-search-category-no;
            padding: 0.0625rem 0.9375rem;
            margin: 0.6rem 1.25rem;
            border-radius: 1.875rem;
            cursor: pointer;
            text-align: center;
          }

          .category-item-col-sel {
            background: $bg-default-s-color;
            color: $select-search-category-s;
          }

          .height-28 {
            height: 1.625rem;

          }

          .category-item-col-brand {
            width: 5.625rem;
            height: 3.125rem;
            margin: 0.6rem 0.3125rem;
            cursor: pointer;

            .category-item-col-brand-img {
              width: 100%;
              height: 100%;
              border: 0.0625rem solid #DCDCDC;
              border-radius: 0.3125rem;
            }

            .category-item-col-brand-img-sel {
              border: 0.0625rem solid $bg-default-s-color;
            }

          }
        }
      }
    }

    .sort-screen-box {
      text-align: left;

      .sort-items-inner{
        flex: 0.6;
        margin-left: -15px; 
        margin-right: -15px;
      }

      .sort-box {
        .sort-box-row {
          display: flex;
          // align-items: center;
          
          .sort-title {
            flex-shrink: 0;
            margin: 0.6rem 0;
            padding: 0.0625rem 0.5rem 0.0625rem 0.9375rem;
            color: $select-search-category-title;
          }

          .sort-item {
            flex-shrink: 0;
            margin: 0.6rem 0.5rem;
            background: #EEEEEE;
            border-radius: 0.8125rem;
            padding: 0.0625rem 0.9375rem;
            display: flex;
            cursor: pointer;

            .text {
              color: $font-title-default-color;
              font-size: 0.9375rem;
              margin: auto;
            }
          }

          .sort-item-sel {
            background: $bg-default-s-color;

            .text {
              color: $select-search-category-s;
            }
          }
        }
      }

      .screen-box {
        .screen-box-row {
          display: flex;
          .screen-title {
            flex-shrink: 0;
            margin: 0.6rem 0;
            padding: 0.0625rem 0.5rem 0.0625rem 0.9375rem;
            color: $font-title-default-color;
          }

          .screen-item {
            flex-shrink: 0;
            margin: 0.6rem 0.5rem;
            background: #EEEEEE;
            border-radius: 0.8125rem;
            padding: 0.0625rem 0.9375rem;
            display: flex;
            cursor: pointer;

            .text {
              color: $font-title-default-color;
              font-size: 0.9375rem;
              margin: auto;
            }
          }

          .screen-item-sel {
            background: $bg-default-s-color;

            .text {
              color: $select-search-category-s;
            }
          }
        }
      }
    }

    .solution-box {
      display: flex;
      flex-direction: row;

      .solution {

        // background: #F7F8F8;
        // box-shadow: 0px 0px 0.9375rem 0px rgba(0, 0, 0, 0.2);
        .vue-waterfall-column {
          .solution-item {
            background: #FFFFFF;
            padding: 0.625rem;
            cursor: pointer;

            .solution-item-ehome {
              box-shadow: 0px 0px 0.375rem 0px rgba(0, 0, 0, 0.2);
              background: #F7F8F8;
              position: relative;

              .collection-button {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.5rem;
                height: 2.5rem;
                top: 0.5rem;
                right: 0.5rem;
                z-index: 9999;
                background: #FFFFFF;
                border-radius: 0.375rem;
                opacity: 0;

                // border: 0.0625rem solid #F0F7FF;
                .collection-msg {
                  opacity: 0;
                  position: absolute;
                  display: flex;
                  top: 2.7rem;
                  background: #000000;
                  color: #FFFFFF;
                  border-radius: 0.25rem;
                  padding: 0.125rem 0.25rem;
                  font-size: 0.5rem;
                }

                &:hover {
                  .collection-msg {
                    opacity: 1;
                  }
                }
              }

              &:hover {
                .collection-button {
                  opacity: 1;
                }
              }

              .solution-img {
                max-width: 100%;
                height: auto;
                z-index: 9998;
              }

              .solution-name {
                padding-top: 0.875rem;
                padding-left: 0.6875rem;
                text-align: left;
                width: 100%;
              }

              .solution-user-box {
                padding: 0.875rem 0.6875rem 0.4375rem 0.6875rem;
                display: flex;
                align-items: center;

                .solution-user-avatar {
                  width: 1.8125rem;
                  height: 1.8125rem;
                  border-radius: 50%;
                }

                .solution-designer-name {
                  padding-left: 0.3125rem;
                  max-width: 80%;
                  overflow: hidden;
                  margin: auto 0;
                }

                .solution-designer-name-mode {
                  padding-left: 0.3125rem;
                  max-width: 70%;
                  overflow: hidden;
                  margin: auto 0;
                }

                .watch-icon {
                  margin-left: auto;
                }

                .icon-padding {
                  padding-left: 0.375rem;
                }

                .solution-watch-number {
                  padding-left: 0.375rem;
                  margin: auto 0;
                }

              }
            }
          }
        }

      }

    }

    .tip{
      padding: 20px 0;
      font-size: 14px;
      color: #999;
    }
    /deep/ .el-pagination {
      .el-pager {
        li:not(.disabled).active {
          background-color: $bg-default-s-color;
          color: #FFF;
        }

        li:not(.disabled):hover {
          color: $bg-default-s-color;
        }

        li:not(.disabled).active:hover {
          color: #FFF;
        }
      }
    }
    
  }



  @media (min-width: 1200px) {
    .container-t-mode-wrap {
      padding: 0 6.25rem;
    }
  }
  
  
  @media screen and (max-width: 1600px) {
    .container-t-mode-wrap{
      .sort-screen-box {
        .sort-items-inner{
          flex: 0.7;
        }
      }
    }
  }
  
  @media screen and (max-width: 1366px) {
    .container-t-mode-wrap{
      .sort-screen-box {
        .sort-items-inner{
          flex: 0.8;
        }
      }
    }
  }
  
  @media screen and (max-width: 1200px) {
    .container-t-mode-wrap{
      .sort-screen-box {
        .sort-items-inner{
          flex: 0.9;
        }
      }
    }
  }
  

  @media screen and (max-width: 767px) {
    .start-design {
      display: none;
    }
  }
</style>
